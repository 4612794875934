import renderComponents from './components';

const NAME = 'sw-mice';

class Mice {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.context = context;

    const businessService = this.context.getService('Business');
    this.page = renderComponents(this.box, { businessService });
  }

  goBack = () => {
    history.back();
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, Mice);
}
