import renderComponents from './components';

const NAME = 'sw-packages-correction';

class PackagesCorrection {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const appService = this.context.getService('App');
    const companyService = this.context.getService('Company');

    this.page = renderComponents(this.box, {
      appService,
      companyService,
      goBack: this.goBack,
      moveToCompany: this.moveToCompany,
    });
  }

  goBack = () => {
    history.back();
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, PackagesCorrection);
}
