import renderComponents from './components';

const NAME = 'sw-payment-hotels-page';

class PaymentHotels {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const businessService = this.context.getService('Business');
    const reviseActTestService = this.context.getService('ReviseActTest');
    const appService = context.getService('App');
    const { params } = appService.get();
    this.page = renderComponents(this.box,
      {
        businessService,
        reviseActTestService,
        params,
      });
  }

  goBack = () => {
    history.back();
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, PaymentHotels);
}
