import React from 'react';
import { createRoot } from 'react-dom/client';
import { Mice } from '../../components/Mice';

const renderComponents = (box, opts) => {
  const { businessService } = opts;

  const page = (
    <Mice
      downloadAlvistReport={ businessService.downloadAlvistReport }
    />
  );

  const root = createRoot(box);

  root.render(page);

  return root;
};

export default renderComponents;

