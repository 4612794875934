import renderComponents from './components';

const NAME = 'sw-constructor-new-document';

class NewDocumentModule {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const documentsConstructorService = context.getService('DocumentsConstructor');
    const appService = context.getService('App');
    const { tabId } = appService.get().params;

    this.page = renderComponents(this.box, {
      documentsConstructorService,
      goBack: this.goBack,
      tabId,
    });
  }

  goBack = () => {
    history.back();
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, NewDocumentModule);
}
