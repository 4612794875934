import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import { Dialog, Button } from 'sw-ui';

import FormDialog from './components/formDialog';
import { WarningForm } from '../../components/WarningForm';

import COMPONENTS from '../../bi/constants/components';

import CircularLoaders from '../../components/loaders';

import styles from './styles/railway.module.css';

const {
  BUTTON: {
    THEME: { SECOND },
  },
  DIALOG: {
    WIDTH: { MIDDLE },
  },
} = COMPONENTS;

const LABELS = {
  PROVIDER: 'Провайдер',
  CONFIRM_BUTTON: 'Отменить билет',
  CONFIRM_DIALOG: 'Билет успешно отменен. Сумма возврата',
  ADD_WARNING: 'Добавить оповещение в ЛК',
};

class RailwayPage extends Component {
  static propTypes = {
    trainCancelService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    railwayWarningService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      loading,
      provider,
      orderId,
      ticketId,
      success,
      resultMessage,
    } = props.trainCancelService.get();
    const { listTemplateWarning, isLoading, isError, type, isEditing, isNewTemplateWarning, textNewTemplateWarning } = props.railwayWarningService.get();
    const { CanManageTemplates } = props.featureFlagsService.get();

    this.state = {
      isOpen: false,
      showWarningForm: false,
      loading,
      provider,
      orderId,
      ticketId,
      success,
      resultMessage,
      flagCanManageTemplates: CanManageTemplates,
      listTemplateWarning,
      isLoading,
      isError,
      type,
      isEditing,
      isNewTemplateWarning,
      textNewTemplateWarning,
    };
  }

  updateState = state => this.setState({
    ...state,
  });

  componentDidMount() {
    const { trainCancelService, featureFlagsService, railwayWarningService } = this.props;

    this.unsubscribe = trainCancelService.subscribe(this.updateState);
    this.unsubFeatureFlags = featureFlagsService.subscribe(this.updateFeatureFlags);
    this.unsubscribeRailwayWarning = railwayWarningService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubFeatureFlags();
    this.unsubscribeRailwayWarning();
  }

  updateFeatureFlags = ({ CanManageTemplates }) => {
    this.setState({
      flagCanManageTemplates: CanManageTemplates,
    });
  };

  handleOpenDialog = () => {
    const { trainCancelService } = this.props;
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen }, () => {
      trainCancelService.resetStore();
    });
  };

  handleChangeInput = (field, value) => this.setState({ [field]: value });

  handleCancelTicket = () => {
    const { trainCancelService } = this.props;
    const { provider, orderId, ticketId } = this.state;

    trainCancelService.cancelTicket(provider, orderId, ticketId);
  };

  handleShowWarningForm = value => this.setState({ showWarningForm: value });

  addFieldTemplateWarning = (isActive) => {
    const { type, textNewTemplateWarning } = this.state;
    const { railwayWarningService: {
        addFieldTemplateWarning,
        setAdditionTemplateWarning,
        setTextTemplateWarning,
    } } = this.props;
    const params = { type, isActive, content: { text: textNewTemplateWarning } };

    addFieldTemplateWarning(params);
    setAdditionTemplateWarning(false);
    setTextTemplateWarning('');
  }

  handleTextAreaChange = value => this.props.railwayWarningService.setTextTemplateWarning(value);

  changeFieldTemplateWarning = ({ id, isActive, content }) => {
    this.props.railwayWarningService.changeFieldTemplateWarning({ widgetId: id, isActive, content: { text: content } });
  }

  deleteFieldTemplateWarning = (id) => {
    this.props.railwayWarningService.deleteFieldTemplateWarning(id);
  }

  handleTemplateWarning = value => this.props.railwayWarningService.setAdditionTemplateWarning(value);

  handleEditTemplate = value => this.props.railwayWarningService.setIsEditing(value);

  renderLoading = () => (
    <Dialog width={ MIDDLE } onClick={ this.handleOpenDialog }>
      <div className={ styles.row }>
        <CircularLoaders wrapClassName='content-loader-wrap' />
      </div>
    </Dialog>
  );

  renderResultDialog = () => {
    const { success, resultMessage, refundSum } = this.state;

    const message = success ? `${LABELS.CONFIRM_DIALOG}: ${refundSum}` : resultMessage;

    return (
      <Dialog width={ MIDDLE } onClick={ this.handleOpenDialog }>
        <div className={ styles.dialog }>
          <div className={ styles.row }>
            { message }
          </div>
        </div>
      </Dialog>
    );
  };

  renderDialog = () => {
    const { loading, provider, orderId, ticketId, resultMessage, success } = this.state;

    if (loading) return this.renderLoading();
    if (resultMessage.length || success) return this.renderResultDialog();

    return (
      <FormDialog
        provider={ provider }
        orderId={ orderId }
        ticketId={ ticketId }
        onChangeInput={ this.handleChangeInput }
        onCancelTicket={ this.handleCancelTicket }
        onOpenDialog={ this.handleOpenDialog }
      />
    );
  };

  renderWarningForm = () => {
    const { railwayWarningService } = this.props;
    const {
      showWarningForm,
      flagCanManageTemplates,
      listTemplateWarning,
      isLoading,
      isError,
      isEditing,
      isNewTemplateWarning,
      textNewTemplateWarning,
    } = this.state;

    if (!flagCanManageTemplates) return null;

    if (!showWarningForm) {
      return (
        <Button
          className={ styles.action }
          theme='second'
          onClick={ () => this.handleShowWarningForm(true) }
        >
          { LABELS.ADD_WARNING }
        </Button>
      );
    }

    return (
      <WarningForm
        onClose={ () => this.handleShowWarningForm(false) }
        listTemplateWarning={ listTemplateWarning }
        isLoading={ isLoading }
        isError={ isError }
        isEditing={ isEditing }
        isNewTemplateWarning={ isNewTemplateWarning }
        textNewTemplateWarning={ textNewTemplateWarning }
        getListTemplateWarning={ railwayWarningService.getListTemplateWarning }
        addFieldTemplateWarning={ this.addFieldTemplateWarning }
        handleTextAreaChange={ this.handleTextAreaChange }
        deleteFieldTemplateWarning={ this.deleteFieldTemplateWarning }
        handleTemplateWarning={ this.handleTemplateWarning }
        handleEditTemplate={ this.handleEditTemplate }
        changeFieldTemplateWarning={ this.changeFieldTemplateWarning }
      />
    );
  }

  render() {
    const { isOpen, showWarningForm } = this.state;

    const dialogHtml = isOpen
      ? this.renderDialog()
      : null;

    return (
      <div className={ styles.wrap }>
        <div>
          <Button
            theme={ SECOND }
            onClick={ this.handleOpenDialog }
            disabled={ showWarningForm }
          >
            { LABELS.CONFIRM_BUTTON }
          </Button>
          { this.renderWarningForm() }
        </div>
        { dialogHtml }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <RailwayPage
      trainCancelService={ opts.trainCancelService }
      featureFlagsService={ opts.featureFlagsService }
      railwayWarningService={ opts.railwayWarningService }
    />
  );

  return root;
};

export default renderComponents;
