import renderComponents from './notifications';

const NAME = 'sw-notifications';

class NotificationsModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const notificationsService = context.getService('Notifications');

    this.page = renderComponents(this.box, {
      notificationsService,
    });
  }

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, NotificationsModule);
}
