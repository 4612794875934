import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CircularLoaders from '../../../components/loaders';
import { HotelItem } from '../components/HotelItem';

import styles from '../styles/search.module.scss';

const LABELS = {
  EMPTYLIST: 'Не найдено ни одного отеля',
  DELETED: 'Отель удален',
  HOTELS: 'Отели',
  REGION: 'По региону',
};

class SearchHotels extends Component {
  static propTypes = {
    hotelsService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();

    const { hotelsByName, hotelsByRegion, isRequest, isNoEdit, mappingGiata } = props.hotelsService.get();

    this.state = {
      isRequest,
      hotelsByName,
      hotelsByRegion,
      isNoEdit,
      mappingGiata,
    };
  }

  componentDidMount() {
    const { hotelsService } = this.props;

    this.unsubscribeHotels = hotelsService.subscribe(this.updateHotels);
  }

  componentWillUnmount() {
    if (this.unsubscribeHotels) {
      this.unsubscribeHotels();
    }
  }

  updateHotels = ({ hotelsByName, hotelsByRegion, isRequest, isNoEdit, mappingGiata }) => {
    this.setState({
      isRequest,
      hotelsByName,
      hotelsByRegion,
      isNoEdit,
      mappingGiata,
    });
  }

  renderLoading = () => <CircularLoaders wrapClassName='content-loader-wrap' />;

  renderEmptyList = () => (
    <div className='empty-list'>
      { LABELS.EMPTYLIST }
    </div>
  );

  renderList = (list, title) => {
    const { hotelsService } = this.props;
    const { mappingGiata } = this.state;

    if (list.length === 0) {
      return null;
    }

    const listHotelsHtml = list.map(item => (
      <HotelItem
        item={ item }
        hotelsService={ hotelsService }
        mappingGiata={ mappingGiata }
      />
    ));

    return (
      <ul className={ styles.list }>
        <h2>{ title }</h2>
        { listHotelsHtml }
      </ul>
    );
  }

  render() {
    const { hotelsByName, hotelsByRegion, isRequest } = this.state;

    if (!hotelsByName.length && !hotelsByRegion.length && !isRequest) {
      return this.renderEmptyList();
    }

    if (isRequest) {
      return this.renderLoading();
    }

    return (
      <div className={ styles.wrap }>
        {this.renderList(hotelsByName, LABELS.HOTELS)}
        {this.renderList(hotelsByRegion, LABELS.REGION)}
      </div>
    );
  }
}

export default SearchHotels;

