import React from 'react';
import PropTypes from 'prop-types';
import { observer } from "mobx-react-lite";

import { rootService } from "../../v2/core/business";

import MenuItem from './menuItem';

import { prepareItems } from '../../bi/utils/menu';
import { redirectToV2 } from '../../v2/utils/redirect';

import { V2_PAGES } from '../../app/routers/menuItems';

import styles from './styles/menu-item.module.css';

const matchNavigate = (
  { type, url },
  old,
) => {
  if (V2_PAGES.includes(type)) {
    return (e) => {
      e.preventDefault();
      redirectToV2(url);
    };
  }

  return old;
};

const Menu = observer(({ items, navigate }) => {
  const { Accountant, Mice, Vacation, Reports, Services, AirlineTaxSettings } = rootService.services.access.ffStore.ffList;
  const preparedItems = prepareItems({
    items,
    services: Services,
    reports: Reports,
    accountant: Accountant,
    mice: Mice,
    vacation: Vacation,
    calculator: AirlineTaxSettings,
  });

  return (
    <ul className={ `navigate ${styles.navigation}` }>
      { preparedItems.map((item, index) => (
        <MenuItem
          key={ index }
          item={ item }
          navigate={ matchNavigate(item, navigate) }
        />
      )) }
    </ul>
  );
});


Menu.propTypes = {
  items: PropTypes.array.isRequired,
  navigate: PropTypes.func.isRequired,
  reports: PropTypes.array,
  services: PropTypes.array,
  accountant: PropTypes.bool,
  calculator: PropTypes.bool,
  mice: PropTypes.bool,
  vacation: PropTypes.bool,
};

Menu.defaultProps = {
  reports: [],
  services: [],
  mice: false,
  accountant: false,
  isCloseMenu: false,
  calculator: false,
  vacation: false,
};

export default Menu;
