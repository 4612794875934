import renderComponents from './components';

const NAME = 'sw-menu';

class LeftMenu {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const workplaceService = this.context.getService('Workplace');
    const appService = this.context.getService('App');
    const featureFlagsService = this.context.getService('FeatureFlags');
    const navigate = this.context.navigate;

    this.page = renderComponents(this.box, {
      workplaceService,
      appService,
      navigate,
      featureFlagsService,
    });
  }

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, LeftMenu);
}
