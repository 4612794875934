import { createRoot } from 'react-dom/client';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Notification from './notification';

import styles from './styles/notifications.module.css';

class Notifications extends Component {
  static propTypes = {
    notificationsService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();

    const {
      notifications,
    } = props.notificationsService.get();

    this.state = {
      notifications,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.notificationsService.subscribe(this.update);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  update = ({ notifications }) => this.setState({ notifications });

  render() {
    const { notificationsService } = this.props;
    const { notifications } = this.state;

    return (
      <div className={ styles.notifications }>
        {
          notifications.map(notice => (
            <Notification
              key={ notice.id }
              notice={ notice }
              notificationsService={ notificationsService }
            />))
        }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <Notifications
      notificationsService={ opts.notificationsService }
    />
  );

  return root;
};

export { Notifications };

export default renderComponents;
